*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background-color: #000E0B;
}
.bg_black2{
  background-color: #000E0B;

}
.border_radius{
  border-radius: 18px;
}
footer{
  background-color: #000E0B;

}
.bg_green2{
  background-color: #032628;
}
.product_img{
  width: 120px;
  height: 100px;
}
.product_img3{
  width: 120px;
  height: 100px;
  filter: invert(1);
}
.product_img2{
  width: 80px;
  height: 80px;
}
.banner_a{
  background-image: url(../public/assets/img/article_back.png);
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-position: top center;
  height: 250px;
}
.padding_top{
  padding-top: 80px;
}
.input_width{
  width: 25ch;
}
.text-area1{
  width: 52ch;
}

.navbar-toggler-icon{
  background-image: url(../public/assets/img/bar.svg);
  filter: invert(1);
}
.rounded-circle{
  width: 170px;
}
@media (max-width:1200px) {
  .input_width{
    width: 52ch;
  }
  .text-area1{
    width: 52ch;
  }
}
@media (max-width:991px) {
  .input_width{
    width: 25ch;
  }
  .text-area1{
    width: 52ch;
  }
}
@media (max-width:475px) {
  .padding_top{
      padding-top: 90px;
  
  }
  .text-area1{
    width: 25ch;
  }
}